import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h2>{`Quem somos`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Com mais de 20 anos de experiência no mercado, a RS Soluções Corporativas possui vasta experiência e respaldo no mercado através do seu network.`}</p>
    </blockquote>
    <p>{`Atua em todo território nacional com parceiros especializados que utilizam alta tecnologia para entregar soluções que mudam a vida do cliente, otimizando processos para as empresas e consolidando resultados estratégicos.`}</p>
    <p>{`Possui soluções que agregam valor para o cliente e impactando positivamente no resultado financeiro da empresa.`}</p>
    <p>{`Dessa forma o compromisso está centrado na economia de recursos para as empresas e de um produto inovador para o cliente.`}</p>
    <p>{`"Pessoas extraordinárias tem uma coisa em comum: um senso incrível de objetivo." - Zig Ziglar`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      