import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Soluções`}</h2>
    <ProjectCard bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
      <center>
  <img src="/image3.png" height="150" />
      </center><br />
 Eficiência enérgica, com soluções de hardware, software, luminárias inteligentes e geração de energia fotovoltaica;
    </ProjectCard>
    <ProjectCard bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
      <center>
  <img src="/image4.png" height="150" />
      </center><br />
  Infraestrutura elétrica (baixa, media e alta tensão), lógica(cabeamento estruturado), fibra óptica, montagem de data center; 
    </ProjectCard>
    <ProjectCard bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
      <center>
  <img src="/image5.png" height="150" />
      </center><br />
  Segurança através de AI utilizando  catracas, cancelas, controle de acesso facial e térmico e câmeras de segurança;
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      